<template>
  <div>
    <div id="top" />
    <div>
      <md-snackbar
        :md-active.sync="showSnackbar"
        :md-duration="8000"
        class="snackbar"
      >
        <span>{{ getContent(1297) }}</span>
      </md-snackbar>

      <md-dialog-prompt
        :md-active.sync="otherPopUp"
        v-model="value"
        :md-title="getContent(1262)"
        :md-content="getContent(1263)"
        :md-input-placeholder="getContent(1264)"
        :md-confirm-text="getContent(1265)"
        :md-cancel-text="getContent(1266)"
        @md-confirm="submitOther(value, questionID), (showSnackbar = true)"
        @md-cancel="(options = null), (otherPopUp = false)"
      />
    </div>
    <md-dialog
      :md-active.sync="SupportOthersPopUp"
      v-on:md-clicked-outside="computeAnswers()"
    >
      <md-dialog-title style="color: #e53935"
        >{{ getContent(1267) }}
      </md-dialog-title>
      <md-dialog-content>
        <div style="margin: 15px">
          {{ getContent(1268) }}
        </div>
        <div style="margin: 15px">
          {{ getContent(1269) }}
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button @click="(SupportOthersPopUp = false), computeAnswers()"
          >{{ getContent(1270) }}
        </md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog
      :md-active.sync="LanguageChangePopUp"
      v-on:md-clicked-outside="
        LanguageChangePopUp == false, LanguageChange(myLanguage)
      "
    >
      <md-dialog-title style="color: #e53935"
        >{{ getContent(1371) }}
      </md-dialog-title>
      <md-dialog-content>
        <div style="margin: 15px">
          {{ getContent(1372) }}
        </div>
        <div style="margin: 15px; display: flex; align-items: center">
          <img
            v-bind:class="{
              'img-language-mobile': myWidth,
              'img-language-desktop': !myWidth,
            }"
            :src="
              'https://media.kaiser.fyi/mindsupport/32/' + myLanguage + '.png'
            "
          />
          <md-field v-bind:class="{ mobile: myWidth, Desktop: !myWidth }">
            <md-select
              v-model="myLanguage"
              name="myLanguage"
              id="myLanguage"
              :placeholder="getContent(1049)"
              required
              @md-selected="LanguageChange"
            >
              <md-option value="EN"
                ><span
                  ><img
                    style="height: 32px; width: 32px"
                    src="https://media.kaiser.fyi/mindsupport/32/EN.png"
                  />
                  English</span
                ></md-option
              >
              <md-option value="DE"
                ><span
                  ><img
                    style="height: 32px; width: 32px"
                    src="https://media.kaiser.fyi/mindsupport/32/DE.png"
                  />
                  Deutsch</span
                ></md-option
              >
              <md-option value="ES"
                ><span
                  ><img
                    style="height: 32px; width: 32px"
                    src="https://media.kaiser.fyi/mindsupport/32/ES.png"
                  />
                  Español</span
                ></md-option
              >
              <md-option value="AR"
                ><span
                  ><img
                    style="height: 32px; width: 32px"
                    src="https://media.kaiser.fyi/mindsupport/32/AR.png"
                  />
                  عربي</span
                ></md-option
              >
              <md-option value="FR"
                ><span
                  ><img
                    style="height: 32px; width: 32px"
                    src="https://media.kaiser.fyi/mindsupport/32/FR.png"
                  />
                  Français</span
                ></md-option
              >
              <md-option value="NL"
                ><span
                  ><img
                    style="height: 32px; width: 32px"
                    src="https://media.kaiser.fyi/mindsupport/32/NL.png"
                  />
                  Nederlands</span
                ></md-option
              >
              <md-option value="RU"
                ><span
                  ><img
                    style="height: 32px; width: 32px"
                    src="https://media.kaiser.fyi/mindsupport/32/RU.png"
                  />
                  Русский</span
                ></md-option
              >
              <md-option value="SE"
                ><span
                  ><img
                    style="height: 32px; width: 32px"
                    src="https://media.kaiser.fyi/mindsupport/32/SE.png"
                  />
                  Svenska</span
                ></md-option
              >
            </md-select>
          </md-field>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button
          @click="(LanguageChangePopUp = false), LanguageChange(myLanguage)"
          >{{ getContent(1090) }}
        </md-button>
      </md-dialog-actions>
    </md-dialog>

    <div v-if="state == 0" class="Information">
      <!-- Desktop -->
      <div v-if="!myWidth">
        <div class="wrapper">
          <div class="background supportOthers">
            <div class="text orange">
              {{ getContent(1008) }}
            </div>
            <div class="text blue">
              {{ getContent(1010) }}
            </div>
            <div class="text lightblue">
              {{ getContent(1009) }}
            </div>
            <div class="text next" @click="SupportOthersPopUp = true">Next</div>
          </div>
        </div>
      </div>
      <!-- Mobile -->
      <div v-if="myWidth">
        <div class="Information1 flexColumn">
          <div
            class="circle-big-mobile"
            style="background: #009688; border: #009688"
          >
            <div
              v-bind:class="{
                'text-mobile': true,
                'text-mobile-NL': NL,
                'text-mobile-DE': DE,
                'text-mobile-FR': FR,
                'text-mobile-AR': AR,
                'text-mobile-ES': ES,
                'text-mobile-RU': RU,
                'text-mobile-SE': SE,
              }"
            >
              {{ getContent(1008) }}
            </div>
          </div>
          <div
            class="circle-big-mobile"
            style="background: #4f83ac; border: #4f83ac"
          >
            <div
              v-bind:class="{
                'text-mobile': true,
                'text-mobile-NL': NL,
                'text-mobile-DE': DE,
                'text-mobile-FR': FR,
                'text-mobile-AR': AR,
                'text-mobile-ES': ES,
                'text-mobile-RU': RU,
                'text-mobile-SE': SE,
              }"
            >
              {{ getContent(1010) }}
            </div>
          </div>
          <div
            class="circle-big-mobile"
            style="background: #009688; border: #009688"
          >
            <div
              v-bind:class="{
                'text-mobile': true,
                'text-mobile-NL': NL,
                'text-mobile-DE': DE,
                'text-mobile-FR': FR,
                'text-mobile-AR': AR,
                'text-mobile-ES': ES,
                'text-mobile-RU': RU,
                'text-mobile-SE': SE,
              }"
            >
              {{ getContent(1009) }}
            </div>
          </div>
          <img
            class="img-mobile"
            @click="SupportOthersPopUp = true"
            src="@/assets/Next_button.png"
          />
        </div>
      </div>
    </div>

    <div v-if="state == 1" class="Information">
      <!-- Desktop -->
      <div v-if="!myWidth">
        <div class="wrapper">
          <div class="background receiveSupport">
            <div class="text orange">
              {{ getContent(1011) }}
            </div>
            <div class="text blue">
              {{ getContent(1013) }}
            </div>
            <div class="text lightblue">
              {{ getContent(1012) }}
            </div>
            <div class="text next" @click="computeAnswers()">Next</div>
          </div>
        </div>
      </div>

      <!-- Mobile -->
      <div v-if="myWidth">
        <div class="Information1 flexColumn">
          <div
            class="circle-big-mobile"
            style="background: #009688; border: #009688"
          >
            <div
              v-bind:class="{
                'text-mobile': true,
                'text-mobile-NL': NL,
                'text-mobile-DE': DE,
                'text-mobile-FR': FR,
                'text-mobile-AR': AR,
                'text-mobile-ES': ES,
                'text-mobile-RU': RU,
                'text-mobile-SE': SE,
              }"
            >
              {{ getContent(1011) }}
            </div>
          </div>
          <div
            class="circle-big-mobile"
            style="background: #4f83ac; border: #4f83ac"
          >
            <div
              v-bind:class="{
                'text-mobile': true,
                'text-mobile-NL': NL,
                'text-mobile-DE': DE,
                'text-mobile-FR': FR,
                'text-mobile-AR': AR,
                'text-mobile-ES': ES,
                'text-mobile-RU': RU,
                'text-mobile-SE': SE,
              }"
            >
              {{ getContent(1013) }}
            </div>
          </div>
          <div
            class="circle-big-mobile"
            style="background: #009688; border: #009688"
          >
            <div
              v-bind:class="{
                'text-mobile': true,
                'text-mobile-NL': NL,
                'text-mobile-DE': DE,
                'text-mobile-FR': FR,
                'text-mobile-AR': AR,
                'text-mobile-ES': ES,
                'text-mobile-RU': RU,
                'text-mobile-SE': SE,
              }"
            >
              {{ getContent(1012) }}
            </div>
          </div>
          <img
            class="img-mobile"
            @click="computeAnswers()"
            src="@/assets/Next_button.png"
          />
        </div>
      </div>
    </div>

    <div v-if="state == 2" class="Information">
      <!-- Desktop -->
      <div v-if="!myWidth">
        <div class="wrapper">
          <div class="background mutualSupport">
            <div class="text orange">
              {{ getContent(1014) }}
            </div>
            <div class="text blue">
              {{ getContent(1016) }}
            </div>
            <div class="text lightblue">
              {{ getContent(1015) }}
            </div>
            <div class="text next" @click="computeAnswers()">Next</div>
          </div>
        </div>
      </div>

      <!-- Mobile -->
      <div v-if="myWidth">
        <div class="Information1 flexColumn">
          <div
            class="circle-big-mobile"
            style="background: #009688; border: #009688"
          >
            <div
              v-bind:class="{
                'text-mobile': true,
                'text-mobile-NL': NL,
                'text-mobile-DE': DE,
                'text-mobile-FR': FR,
                'text-mobile-AR': AR,
                'text-mobile-ES': ES,
                'text-mobile-RU': RU,
                'text-mobile-SE': SE,
              }"
            >
              {{ getContent(1014) }}
            </div>
          </div>
          <div
            class="circle-big-mobile"
            style="background: #009688; border: #009688"
          >
            <div
              v-bind:class="{
                'text-mobile': true,
                'text-mobile-NL': NL,
                'text-mobile-DE': DE,
                'text-mobile-FR': FR,
                'text-mobile-AR': AR,
                'text-mobile-ES': ES,
                'text-mobile-RU': RU,
                'text-mobile-SE': SE,
              }"
            >
              {{ getContent(1015) }}
            </div>
          </div>
          <div
            class="circle-big-mobile"
            style="background: #4f83ac; border: #4f83ac"
          >
            <div
              v-bind:class="{
                'text-mobile': true,
                'text-mobile-NL': NL,
                'text-mobile-DE': DE,
                'text-mobile-FR': FR,
                'text-mobile-AR': AR,
                'text-mobile-ES': ES,
                'text-mobile-RU': RU,
                'text-mobile-SE': SE,
              }"
            >
              {{ getContent(1016) }}
            </div>
          </div>
          <img
            class="img-mobile"
            @click="computeAnswers()"
            src="@/assets/Next_button.png"
          />
        </div>
      </div>
    </div>

    <div v-if="state == 3" class="Information">
      <!-- Desktop -->
      <div v-if="!myWidth">
        <div class="Information1 flexRow">
          <div
            class="circle-big_which"
            @click="changeState(0)"
            style="background: #009688; border: #009688; cursor: pointer"
          >
            <div
              v-bind:class="{
                text: true,
                'text-NL': NL,
                'text-DE': DE,
                'text-FR': FR,
                'text-AR': AR,
                'text-ES': ES,
                'text-RU': RU,
                'text-SE': SE,
              }"
            >
              {{ getContent(4) }} {{ getContent(5) }}
            </div>
            <div
              v-bind:class="{
                'text-small': true,
                'text-small-NL': NL,
                'text-small-DE': DE,
                'text-small-FR': FR,
                'text-small-RU': RU,
              }"
            >
              {{ getContent(1017) }}
            </div>
          </div>
          <div class="circle-small" style="background: #4f83ac"></div>
          <div
            class="circle-big_which"
            @click="changeState(1)"
            style="background: #009688; border: #009688; cursor: pointer"
          >
            <div
              v-bind:class="{
                text: true,
                'text-NL': NL,
                'text-DE': DE,
                'text-FR': FR,
                'text-AR': AR,
                'text-ES': ES,
                'text-RU': RU,
                'text-SE': SE,
              }"
            >
              {{ getContent(1) }} {{ getContent(2) }}
            </div>
            <div
              v-bind:class="{
                'text-small': true,
                'text-small-NL': NL,
                'text-small-DE': DE,
                'text-small-FR': FR,
                'text-small-RU': RU,
              }"
            >
              {{ getContent(1018) }}
            </div>
          </div>
        </div>
        <div class="Information2 flexRow">
          <div class="circle-small" style="background: #009688"></div>
          <div
            class="circle-big_which"
            @click="changeState(2)"
            style="background: #4f83ac; border: #4f83ac; cursor: pointer"
          >
            <div
              v-bind:class="{
                text: true,
                'text-NL': NL,
                'text-DE': DE,
                'text-FR': FR,
                'text-AR': AR,
                'text-ES': ES,
                'text-RU': RU,
                'text-SE': SE,
              }"
            >
              {{ getContent(11) }} {{ getContent(12) }}
            </div>
            <div
              v-bind:class="{
                'text-small': true,
                'text-small-NL': NL,
                'text-small-DE': DE,
                'text-small-FR': FR,
                'text-small-RU': RU,
              }"
            >
              {{ getContent(1019) }}
            </div>
          </div>
          <div class="circle-small" style="background: #009688"></div>
          <img
            class="img"
            src="@/assets/Next_button.png"
            style="visibility: hidden"
          />
        </div>
      </div>
      <!-- Mobile -->
      <div v-if="myWidth">
        <div class="Information1 flexColumn">
          <div
            class="circle-big_which-mobile"
            @click="changeState(0)"
            style="background: #009688; border: #009688; cursor: pointer"
          >
            <div
              v-bind:class="{
                'text-mobile': true,
                'text-mobile-NL': NL,
                'text-mobile-DE': DE,
                'text-mobile-FR': FR,
                'text-mobile-AR': AR,
                'text-mobile-ES': ES,
                'text-mobile-RU': RU,
                'text-mobile-SE': SE,
              }"
            >
              {{ getContent(4) }} {{ getContent(5) }}
            </div>
            <div
              v-bind:class="{
                'text-small-mobile': true,
                'text-small-mobile-NL': NL,
                'text-small-mobile-DE': DE,
                'text-small-mobile-FR': FR,
                'text-small-mobile-RU': RU,
              }"
            >
              {{ getContent(1017) }}
            </div>
          </div>
          <div
            class="circle-big_which-mobile"
            @click="changeState(2)"
            style="background: #4f83ac; border: #4f83ac; cursor: pointer"
          >
            <div
              v-bind:class="{
                'text-mobile': true,
                'text-mobile-NL': NL,
                'text-mobile-DE': DE,
                'text-mobile-FR': FR,
                'text-mobile-AR': AR,
                'text-mobile-ES': ES,
                'text-mobile-RU': RU,
                'text-mobile-SE': SE,
              }"
            >
              {{ getContent(11) }} {{ getContent(12) }}
            </div>
            <div
              v-bind:class="{
                'text-small-mobile': true,
                'text-small-mobile-NL': NL,
                'text-small-mobile-DE': DE,
                'text-small-mobile-FR': FR,
                'text-small-mobile-RU': RU,
              }"
            >
              {{ getContent(1019) }}
            </div>
          </div>
          <div
            class="circle-big_which-mobile"
            @click="changeState(1)"
            style="background: #009688; border: #009688; cursor: pointer"
          >
            <div
              v-bind:class="{
                'text-mobile': true,
                'text-mobile-NL': NL,
                'text-mobile-DE': DE,
                'text-mobile-FR': FR,
                'text-mobile-AR': AR,
                'text-mobile-ES': ES,
                'text-mobile-RU': RU,
                'text-mobile-SE': SE,
              }"
            >
              {{ getContent(1) }} {{ getContent(2) }}
            </div>
            <div
              v-bind:class="{
                'text-small-mobile': true,
                'text-small-mobile-NL': NL,
                'text-small-mobile-DE': DE,
                'text-small-mobile-FR': FR,
                'text-small-mobile-RU': RU,
              }"
            >
              {{ getContent(1018) }}
            </div>
          </div>
          <img
            class="img"
            src="@/assets/Next_button.png"
            style="visibility: hidden"
          />
        </div>
      </div>
    </div>

    <div v-if="state == 4" class="Questionnaire">
      <!-- Desktop -->
      <div v-if="!myWidth">
        <div class="Questionnaire1">
          <div style="width: 85vw; padding-top: 2vw">
            {{ getContent(1251) }}
          </div>
          <div class="circle-questionnaire">
            {{ myStack.length }} {{ getContent(1252) }}
          </div>
        </div>
        <div class="Questionnaire2">
          <div
            class="Questionnaire_Text"
            style="color: #ffffff; width: 38%"
          ></div>
          <div class="Questionnaire_Text">{{ getContent(1020) }}</div>
          <div class="Questionnaire_Text">{{ getContent(1021) }}</div>
          <div class="Questionnaire_Text">{{ getContent(1022) }}</div>
          <div class="Questionnaire_Text">{{ getContent(1023) }}</div>
          <div style="width: 10vw; min-width: 45px"></div>
        </div>
        <div
          class="Questionnaire3"
          v-for="(answer, key) in answers"
          v-bind:key="key"
        >
          <div style="width: 4vw"></div>
          <div
            class="Questionnaire_Text"
            style="margin: 0; width: 34%; text-align: left"
          >
            {{ key + 1 + ". " + answer.answer }}
          </div>
          <div class="Checkbox">
            <md-radio v-model="aorn[key]" value="a"></md-radio>
          </div>
          <div class="Checkbox">
            <md-radio v-model="aorn[key]" value="o"></md-radio>
          </div>
          <div class="Checkbox">
            <md-radio v-model="aorn[key]" value="r"></md-radio>
          </div>
          <div class="Checkbox">
            <md-radio v-model="aorn[key]" value="n"></md-radio>
          </div>
          <div style="width: 10vw; min-width: 45px"></div>
        </div>
        <img
          class="img"
          @click="computeAnswers()"
          src="@/assets/Next_button.png"
          style="display: flex; float: right; margin: 5vw"
        />
      </div>

      <!-- Mobile -->
      <div v-if="myWidth">
        <div class="Questionnaire1-mobile">
          <div style="width: 85vw; padding-top: 2vw">
            {{ getContent(1251) }}
          </div>
          <div class="circle-questionnaire-mobile">
            {{ myStack.length }} {{ getContent(1252) }}
          </div>
        </div>
        <div class="Questionnaire2-mobile">
          <div
            class="Questionnaire_Text-mobile"
            style="color: #ffffff; width: 52vw"
          ></div>
          <div class="Questionnaire_Text-mobile">{{ getContent(1020) }}</div>
          <div class="Questionnaire_Text-mobile">{{ getContent(1021) }}</div>
          <div class="Questionnaire_Text-mobile">{{ getContent(1022) }}</div>
          <div class="Questionnaire_Text-mobile">{{ getContent(1023) }}</div>
        </div>
        <div
          class="Questionnaire3"
          v-for="(answer, key) in answers"
          v-bind:key="key"
        >
          <div
            class="Questionnaire_Text-mobile"
            style="margin-left: 4vw; width: 48vw; text-align: left"
          >
            {{ key + 1 + ". " + answer.answer }}
          </div>
          <div class="Checkbox">
            <md-radio v-model="aorn[key]" value="a"></md-radio>
          </div>
          <div class="Checkbox">
            <md-radio v-model="aorn[key]" value="o"></md-radio>
          </div>
          <div class="Checkbox">
            <md-radio v-model="aorn[key]" value="r"></md-radio>
          </div>
          <div class="Checkbox">
            <md-radio v-model="aorn[key]" value="n"></md-radio>
          </div>
        </div>
        <img
          class="img-mobile"
          @click="computeAnswers()"
          src="@/assets/Next_button.png"
          style="display: flex; float: right; margin: 5vw"
        />
      </div>
    </div>

    <div v-if="state == 5">
      <!-- Desktop -->
      <div v-if="!myWidth">
        <div class="Scale1 flexRow">
          <div style="width: 85vw; padding-top: 2vw">
            {{ getContent(questionID) }}
          </div>
          <div class="circle-questionnaire">
            {{ myStack.length }} {{ getContent(1252) }}
          </div>
        </div>
        <div class="slidecontainer">
          <input
            type="range"
            min="0"
            max="10"
            class="slider"
            id="myRange"
            v-model="slider"
          />
          {{ slider }}
        </div>
        <img
          class="img"
          @click="computeAnswers()"
          src="@/assets/Next_button.png"
          style="display: flex; float: right; margin: 5vw"
        />
      </div>

      <!-- Mobile -->
      <div v-if="myWidth">
        <div class="Scale1-mobile flexRow">
          <div style="width: 85vw; padding-top: 2vw">
            {{ getContent(questionID) }}
          </div>
          <div class="circle-questionnaire-mobile">
            {{ myStack.length }} {{ getContent(1252) }}
          </div>
        </div>
        <div class="slidecontainer-mobile">
          <input
            type="range"
            min="0"
            max="10"
            class="slider"
            id="myRange"
            v-model="slider"
          />
          {{ slider }}
        </div>
        <img
          class="img-mobile"
          @click="computeAnswers()"
          src="@/assets/Next_button.png"
          style="display: flex; float: right; margin: 5vw"
        />
      </div>
    </div>

    <div v-if="state == 6">
      <!-- Desktop -->
      <div v-if="!myWidth">
        <div class="Questions flexRow">
          <div style="width: 85vw; padding-top: 2vw">
            {{ getContent(questionID) }}
          </div>
          <div class="circle-questionnaire">
            {{ myStack.length }} {{ getContent(1252) }}
          </div>
        </div>
        <div class="Questions flexColumn">
          <div class="Questions1">
            <md-radio
              v-model="options"
              :value="item.id"
              v-for="(item, key) in answers"
              v-bind:key="key"
              :change="otherSelected()"
              >{{ item.answer }}
            </md-radio>
          </div>
        </div>
        <img
          class="img"
          @click="computeAnswers()"
          src="@/assets/Next_button.png"
          style="display: flex; float: right; margin: 5vw"
        />
      </div>

      <!-- Mobile -->
      <div v-if="myWidth">
        <div class="Questions-mobile flexRow">
          <div style="width: 85vw; padding-top: 2vw">
            {{ getContent(questionID) }}
          </div>
          <div class="circle-questionnaire-mobile">
            {{ myStack.length }} {{ getContent(1252) }}
          </div>
        </div>
        <div class="Questions-mobile flexColumn">
          <div class="Questions1" style="margin: 4vw">
            <md-radio
              v-model="options"
              :value="item.id"
              v-for="(item, key) in answers"
              v-bind:key="key"
              >{{ item.answer }}
            </md-radio>
          </div>
        </div>
        <img
          class="img-mobile"
          @click="computeAnswers()"
          src="@/assets/Next_button.png"
          style="display: flex; float: right; margin: 5vw"
        />
      </div>
    </div>

    <div v-if="state == 7">
      <!-- Desktop -->
      <div v-if="!myWidth">
        <div class="Questions flexRow">
          <div style="width: 85vw; padding-top: 2vw">
            {{ getContent(questionID) }}
          </div>
          <div class="circle-questionnaire">
            {{ myStack.length }} {{ getContent(1252) }}
          </div>
        </div>
        <div class="Questions flexRow">
          <md-table
            v-model="searched"
            md-card
            @md-selected="onSelect"
            style="min-width: 85vw; max-width: 85vw"
          >
            <md-table-toolbar>
              <div class="md-toolbar-section-start">
                <!-- <h1 class="md-title">Users</h1> -->
              </div>

              <md-field md-clearable class="md-toolbar-section-end">
                <md-input
                  :placeholder="getContent(1027)"
                  v-model="search"
                  @input="searchOnTable"
                />
              </md-field>
            </md-table-toolbar>

            <md-table-empty-state
              :md-label="getContent(1028)"
              :md-description="getContent(1029) + getContent(1030)"
            >
            </md-table-empty-state>

            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              md-selectable="multiple"
              md-auto-select
            >
              <md-table-cell :md-label="getContent(1361)"
                >{{ item.answer }}
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
        <img
          class="img"
          @click="computeAnswers()"
          src="@/assets/Next_button.png"
          style="display: flex; float: right; margin: 5vw"
        />
      </div>

      <!-- Mobile -->
      <div v-if="myWidth">
        <div class="Questions-mobile flexRow">
          <div style="width: 85vw; padding-top: 2vw">
            {{ getContent(questionID) }}
          </div>
          <div class="circle-questionnaire-mobile">
            {{ myStack.length }} {{ getContent(1252) }}
          </div>
        </div>
        <div class="Questions-mobile flexRow" style="padding: 0; margin: 0">
          <md-table
            v-model="searched"
            md-card
            @md-selected="onSelect"
            style="width: 100vw; margin-top: 5vw"
          >
            <md-table-toolbar>
              <div class="md-toolbar-section-start">
                <!-- <h1 class="md-title">Users</h1> -->
              </div>

              <md-field md-clearable class="md-toolbar-section-end">
                <md-input
                  :placeholder="getContent(1027)"
                  v-model="search"
                  @input="searchOnTable"
                />
              </md-field>
            </md-table-toolbar>

            <md-table-empty-state
              :md-label="getContent(1028)"
              :md-description="getContent(1029) + getContent(1030)"
            >
            </md-table-empty-state>

            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              md-selectable="multiple"
              md-auto-select
            >
              <md-table-cell
                style="font-size: 5vw; line-height: 5vw; max-width: 85vw"
                :md-label="getContent(1361)"
                >{{ item.answer }}
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
        <img
          class="img-mobile"
          @click="computeAnswers()"
          src="@/assets/Next_button.png"
          style="display: flex; float: right; margin: 5vw"
        />
      </div>
    </div>

    <div v-if="state == 8">
      <!-- Desktop -->
      <div v-if="!myWidth">
        <md-dialog
          :md-active.sync="complete"
          @md-clicked-outside="(complete = false), (showDialog = true)"
        >
          <md-dialog-title>{{ getContent(1293) }}</md-dialog-title>
          <md-dialog-content style="margin: 1vw">
            {{ getContent(1294) }}
            <br />
            <br />
            <a href="mailto:contact@mindsupport.eu">contact@mindsupport.eu</a>
          </md-dialog-content>

          <md-dialog-actions>
            <md-button
              class="md-primary"
              @click="(complete = false), (showDialog = true)"
              >Okay
            </md-button>
          </md-dialog-actions>
        </md-dialog>

        <md-dialog :md-active.sync="showDialog">
          <md-dialog-title>{{ getContent(1034) }}</md-dialog-title>

          <md-tabs md-dynamic-height>
            <template slot="md-tab" slot-scope="{ tab }">
              <p v-if="tab.data.show" style="color: red">{{ tab.label }}</p>
              <p v-else>{{ tab.label }}</p>
            </template>
            <md-tab
              :md-label="getContent(1035)"
              :md-template-data="{ show: true }"
            >
              <p>{{ getContent(1036) }}</p>
              <p>{{ getContent(1037) }}</p>
            </md-tab>

            <md-tab md-label=" M. Parker ">
              <p>{{ getContent(1038) }} M. Parker</p>
              <!-- Name -->
              <p>{{ getContent(1039) }} {{ getContent(1040) }}</p>
              <!-- Profession -->
              <p>{{ getContent(1041) }} {{ getContent(1042) }}</p>
              <!-- Price -->
              <p>{{ getContent(1043) }} {{ getContent(1044) }}</p>
              <!-- Format -->
              <p>{{ getContent(1045) }} {{ getContent(1046) }}</p>
              <!-- Location -->
              <p>{{ getContent(1047) }} {{ getContent(1048) }}</p>
              <!-- Specialty -->
              <p>{{ getContent(1049) }} {{ getContent(1050) }}</p>
              <!-- Language -->
              <p>
                {{ getContent(1051) }}
                <a target="_blank" href="https://www.mparkercounselling.com/"
                  >www.mparkercounselling.com</a
                >
              </p>
              <!-- Website -->
              <br />
              <p>{{ getContent(1292) }}</p>
            </md-tab>
            <md-tab md-label=" T. Korchinski ">
              <p>{{ getContent(1038) }} T. Korchinski</p>
              <!-- Name -->
              <p>{{ getContent(1039) }} {{ getContent(1040) }}</p>
              <!-- Profession -->
              <p>{{ getContent(1041) }} {{ getContent(1052) }}</p>
              <!-- Price -->
              <p>{{ getContent(1043) }} {{ getContent(1044) }}</p>
              <!-- Format -->
              <p>{{ getContent(1045) }} {{ getContent(1053) }}</p>
              <!-- Location -->
              <p>{{ getContent(1047) }} {{ getContent(1054) }}</p>
              <!-- Specialty -->
              <p>{{ getContent(1049) }} {{ getContent(1050) }}</p>
              <!-- Language -->
              <p>
                {{ getContent(1051) }}
                <a target="_blank" href="https://www.tlkpsyservices.com/"
                  >www.tlkpsyservices.com</a
                >
              </p>
              <!-- Website -->
              <br />
              <p>{{ getContent(1292) }}</p>
            </md-tab>
            <md-tab md-label=" Natasha Bridgelal ">
              <p>{{ getContent(1038) }} Natasha Bridgelal</p>
              <!-- Name -->
              <p>{{ getContent(1039) }} {{ getContent(1040) }}</p>
              <!-- Profession -->
              <p>{{ getContent(1041) }} {{ getContent(1091) }}</p>
              <!-- Price -->
              <p>{{ getContent(1043) }} {{ getContent(1092) }}</p>
              <!-- Format -->
              <p>{{ getContent(1047) }} {{ getContent(1093) }}</p>
              <!-- Specialty -->
              <p>{{ getContent(1049) }} {{ getContent(1050) }}</p>
              <!-- Language -->
              <p>
                {{ getContent(1051) }}
                <a target="_blank" href="https://www.bridgetoharmony.com/"
                  >www.bridgetoharmony.com</a
                >
              </p>
              <!-- Website -->
              <br />
              <p>{{ getContent(1292) }}</p>
            </md-tab>
            <md-tab md-label=" Ghislaine Jonker ">
              <p>{{ getContent(1038) }} Your Happiness (Ghislaine Jonker)</p>
              <!-- Name -->
              <p>{{ getContent(1039) }} {{ getContent(1040) }}</p>
              <!-- Profession -->
              <p>{{ getContent(1041) }} {{ getContent(1094) }}</p>
              <!-- Price -->
              <p>{{ getContent(1043) }} {{ getContent(1044) }}</p>
              <!-- Format -->
              <p>{{ getContent(1045) }} {{ getContent(1095) }}</p>
              <!-- Location -->
              <p>{{ getContent(1047) }} {{ getContent(1096) }}</p>
              <!-- Specialty -->
              <p>{{ getContent(1049) }} {{ getContent(1097) }}</p>
              <!-- Language -->
              <p>
                {{ getContent(1051) }}
                <a target="_blank" href="https://yourhappiness.eu/"
                  >www.yourhappiness.eu</a
                >
              </p>
              <!-- Website -->
              <br />
              <p>{{ getContent(1292) }}</p>
            </md-tab>
            <md-tab md-label=" Chrysanthi Sophia Karampetsi ">
              <p>
                {{ getContent(1038) }} Coach Psychologist (Chrysanthi Sophia
                Karampetsi)
              </p>
              <!-- Name -->
              <p>{{ getContent(1039) }} {{ getContent(1040) }}</p>
              <!-- Profession -->
              <p>{{ getContent(1041) }} {{ getContent(1236) }}</p>
              <!-- Price -->
              <p>{{ getContent(1043) }} {{ getContent(1092) }}</p>
              <!-- Format -->
              <p>{{ getContent(1047) }} {{ getContent(1237) }}</p>
              <!-- Specialty -->
              <p>{{ getContent(1049) }} {{ getContent(1238) }}</p>
              <!-- Language -->
              <p>
                {{ getContent(1051) }}
                <a target="_blank" href="https://www.coachpsychologist.com/"
                  >www.coachpsychologist.com</a
                >
              </p>
              <!-- Website -->
              <br />
              <p>{{ getContent(1292) }}</p>
            </md-tab>
            <md-tab md-label=" Luciana Yabar ">
              <p>{{ getContent(1038) }} Luciana Yabar</p>
              <!-- Name -->
              <p>{{ getContent(1039) }} {{ getContent(1300) }}</p>
              <!-- Profession -->
              <p>{{ getContent(1041) }} {{ getContent(1301) }}</p>
              <!-- Price -->
              <p>{{ getContent(1043) }} {{ getContent(1092) }}</p>
              <!-- Format -->
              <p>{{ getContent(1047) }} {{ getContent(1302) }}</p>
              <!-- Specialty -->
              <p>{{ getContent(1049) }} {{ getContent(1303) }}</p>
              <!-- Language -->
              <p>
                {{ getContent(1051) }}
                <a target="_blank" href="https://paskay.com">www.paskay.com</a>
              </p>
              <!-- Website -->
              <br />
              <p>{{ getContent(1292) }}</p>
            </md-tab>
          </md-tabs>
          <md-progress-bar md-mode="query" v-if="query"></md-progress-bar>
          <div
            style="margin: 5px; font-size: 0.8em; line-height: 0.8em"
            v-if="query"
          >
            {{ getContent(1253) }}
          </div>
          <md-dialog-actions>
            <md-button class="md-primary" @click="showDialog = false"
              >{{ getContent(1090) }}
            </md-button>
          </md-dialog-actions>
        </md-dialog>

        <div class="Choose flexRow">
          <div class="flexColumn" style="padding: 4vw">
            <div
              v-bind:class="{
                'circle-mid': true,
                'circle-mid-DE': DE,
                'circle-mid-FR': FR,
                'circle-mid-ES': ES,
                'circle-mid-RU': RU,
              }"
            >
              {{ getContent(1024) }} {{ buddyData.buddies.length }}
              {{ getContent(1025) }}
            </div>
            <div
              v-bind:class="{
                'circle-mid': true,
                'circle-mid-DE': DE,
                'circle-mid-FR': FR,
                'circle-mid-ES': ES,
                'circle-mid-RU': RU,
              }"
            >
              {{ getContent(1026) }}
            </div>
          </div>
          <div style="width: 2%; background: #cccccc"></div>
          <div
            class="flexColumn"
            style="padding-top: 5vw; justify-content: center"
          >
            <div
              v-for="(bud, key) in buddyData.buddies"
              v-bind:key="key"
              :class="{
                Selection: buddy != key + 1,
                Selected: buddy == key + 1,
              }"
              @click="
                buddy = key + 1;
                scroll('#scrolltodiv');
              "
            >
              {{ bud.name }}: {{ bud.aboutMe }}
            </div>
            <div
              v-if="buddyData.buddies.length === 0"
              class="Selection"
              @click="link('Signin')"
            >
              {{ getContent(1239) }}
            </div>
            <div
              :class="{ TryOut: buddy != 0, TryOutGray: buddy == null }"
              @click="selectBuddy()"
            >
              {{ getContent(1031) }}
            </div>
          </div>
        </div>
      </div>

      <!-- Mobile -->
      <div v-if="myWidth">
        <md-dialog :md-active.sync="showDialog">
          <md-dialog-title>{{ getContent(1034) }}</md-dialog-title>

          <md-field>
            <label for="Selection"></label>
            <md-select
              v-model="Selection"
              name="Selection"
              id="Selection"
              style="margin-left: 4vw"
            >
              <md-option value="0"> {{ getContent(1035) }}</md-option>
              <md-option value="1"> M. Parker</md-option>
              <md-option value="2"> T. Korchinski</md-option>
              <md-option value="3"> Natasha Bridgelal</md-option>
              <md-option value="4"> Ghislaine Jonker</md-option>
              <md-option value="5"> Chrysanthi Sophia Karampetsi</md-option>
              <md-option value="6"> Luciana Yabar</md-option>
            </md-select>
          </md-field>
          <div v-if="Selection == '0'" style="margin: 4vw">
            <p>{{ getContent(1036) }}</p>
            <p>{{ getContent(1037) }}</p>
          </div>
          <div v-else-if="Selection == 1" style="margin: 4vw">
            <!-- Name -->
            <p>
              <b>{{ getContent(1038) }}</b> M. Parker
            </p>
            <!-- Profession -->
            <p>
              <b>{{ getContent(1039) }}</b> {{ getContent(1040) }}
            </p>
            <!-- Price -->
            <p>
              <b>{{ getContent(1041) }}</b> {{ getContent(1042) }}
            </p>
            <!-- Format -->
            <p>
              <b>{{ getContent(1043) }}</b> {{ getContent(1044) }}
            </p>
            <!-- Location -->
            <p>
              <b>{{ getContent(1045) }}</b> {{ getContent(1046) }}
            </p>
            <!-- Specialty -->
            <p>
              <b>{{ getContent(1047) }}</b> {{ getContent(1048) }}
            </p>
            <!-- Language -->
            <p>
              <b>{{ getContent(1049) }}</b> {{ getContent(1050) }}
            </p>
            <!-- Website -->
            <p>
              <b>{{ getContent(1051) }}</b>
              <a target="_blank" href="https://www.mparkercounselling.com/"
                >www.mparkercounselling.com</a
              >
            </p>
          </div>
          <div v-else-if="Selection == 2" style="margin: 4vw">
            <!-- Name -->
            <p>
              <b>{{ getContent(1038) }}</b> T. Korchinski
            </p>
            <!-- Profession -->
            <p>
              <b>{{ getContent(1039) }}</b> {{ getContent(1040) }}
            </p>
            <!-- Price -->
            <p>
              <b>{{ getContent(1041) }}</b> {{ getContent(1052) }}
            </p>
            <!-- Format -->
            <p>
              <b>{{ getContent(1043) }}</b> {{ getContent(1044) }}
            </p>
            <!-- Location -->
            <p>
              <b>{{ getContent(1045) }}</b> {{ getContent(1053) }}
            </p>
            <!-- Specialty -->
            <p>
              <b>{{ getContent(1047) }}</b> {{ getContent(1054) }}
            </p>
            <!-- Language -->
            <p>
              <b>{{ getContent(1049) }}</b> {{ getContent(1050) }}
            </p>
            <!-- Website -->
            <p>
              <b>{{ getContent(1051) }}</b>
              <a target="_blank" href="https://www.tlkpsyservices.com/"
                >www.tlkpsyservices.com</a
              >
            </p>
          </div>
          <div v-else-if="Selection == 3" style="margin: 4vw">
            <!-- Name -->
            <p>
              <b>{{ getContent(1038) }}</b> Natasha Bridgelal
            </p>
            <!-- Profession -->
            <p>
              <b>{{ getContent(1039) }}</b> {{ getContent(1040) }}
            </p>
            <!-- Price -->
            <p>
              <b>{{ getContent(1041) }}</b> {{ getContent(1091) }}
            </p>
            <!-- Format -->
            <p>
              <b>{{ getContent(1043) }}</b> {{ getContent(1092) }}
            </p>
            <!-- Specialty -->
            <p>
              <b>{{ getContent(1047) }}</b> {{ getContent(1093) }}
            </p>
            <!-- Language -->
            <p>
              <b>{{ getContent(1049) }}</b> {{ getContent(1050) }}
            </p>
            <!-- Website -->
            <p>
              <b>{{ getContent(1051) }}</b>
              <a target="_blank" href="https://www.bridgetoharmony.com/"
                >www.bridgetoharmony.com</a
              >
            </p>
          </div>
          <div v-else-if="Selection == 4" style="margin: 4vw">
            <!-- Name -->
            <p>
              <b>{{ getContent(1038) }}</b> Your Happiness (Ghislaine Jonker)
            </p>
            <!-- Profession -->
            <p>
              <b>{{ getContent(1039) }}</b> {{ getContent(1040) }}
            </p>
            <!-- Price -->
            <p>
              <b>{{ getContent(1041) }}</b> {{ getContent(1094) }}
            </p>
            <!-- Format -->
            <p>
              <b>{{ getContent(1043) }}</b> {{ getContent(1044) }}
            </p>
            <!-- Location -->
            <p>
              <b>{{ getContent(1045) }}</b> {{ getContent(1095) }}
            </p>
            <!-- Specialty -->
            <p>
              <b>{{ getContent(1047) }}</b> {{ getContent(1096) }}
            </p>
            <!-- Language -->
            <p>
              <b>{{ getContent(1049) }}</b> {{ getContent(1097) }}
            </p>
            <!-- Website -->
            <p>
              <b>{{ getContent(1051) }}</b>
              <a target="_blank" href="https://yourhappiness.eu/"
                >www.yourhappiness.eu</a
              >
            </p>
          </div>
          <div v-else-if="Selection == 5" style="margin: 4vw">
            <!-- Name -->
            <p>
              <b>{{ getContent(1038) }}</b> Coach Psychologist (Chrysanthi
              Sophia Karampetsi)
            </p>
            <!-- Profession -->
            <p>
              <b>{{ getContent(1039) }}</b> {{ getContent(1040) }}
            </p>
            <!-- Price -->
            <p>
              <b>{{ getContent(1041) }}</b> {{ getContent(1236) }}
            </p>
            <!-- Format -->
            <p>
              <b>{{ getContent(1043) }}</b> {{ getContent(1092) }}
            </p>
            <!-- Specialty -->
            <p>
              <b>{{ getContent(1047) }}</b> {{ getContent(1237) }}
            </p>
            <!-- Language -->
            <p>
              <b>{{ getContent(1049) }}</b> {{ getContent(1238) }}
            </p>
            <!-- Website -->
            <p>
              <b>{{ getContent(1051) }}</b>
              <a target="_blank" href="https://www.coachpsychologist.com/"
                >www.coachpsychologist.com</a
              >
            </p>
          </div>
          <div v-else-if="Selection == 6" style="margin: 4vw">
            <!-- Name -->
            <p>
              <b>{{ getContent(1038) }}</b> Luciana Yabar
            </p>
            <!-- Profession -->
            <p>
              <b>{{ getContent(1039) }}</b> {{ getContent(1300) }}
            </p>
            <!-- Price -->
            <p>
              <b>{{ getContent(1041) }}</b> {{ getContent(1301) }}
            </p>
            <!-- Format -->
            <p>
              <b>{{ getContent(1043) }}</b> {{ getContent(1092) }}
            </p>
            <!-- Specialty -->
            <p>
              <b>{{ getContent(1047) }}</b> {{ getContent(1302) }}
            </p>
            <!-- Language -->
            <p>
              <b>{{ getContent(1049) }}</b> {{ getContent(1303) }}
            </p>
            <!-- Website -->
            <p>
              <b>{{ getContent(1051) }}</b>
              <a target="_blank" href="https://paskay.com">www.paskay.com</a>
            </p>
          </div>

          <md-progress-bar md-mode="query" v-if="query"></md-progress-bar>
          <div
            style="margin: 5px; font-size: 0.8em; line-height: 0.8em"
            v-if="query"
          >
            {{ getContent(1253) }}
          </div>
          <md-dialog-actions>
            <md-button class="md-primary" @click="showDialog = false"
              >{{ getContent(1090) }}
            </md-button>
          </md-dialog-actions>
        </md-dialog>

        <div class="Choose flexColumn">
          <div
            class="flexRow"
            style="padding: 4vw; justify-content: space-evenly"
          >
            <div
              v-bind:class="{
                'circle-mid-mobile': true,
                'circle-mid-mobile-DE': DE,
                'circle-mid-mobile-FR': FR,
                'circle-mid-mobile-ES': ES,
                'circle-mid-mobile-RU': RU,
              }"
            >
              {{ getContent(1024) }} {{ buddyData.buddies.length }}
              {{ getContent(1025) }}
            </div>
            <div
              v-bind:class="{
                'circle-mid-mobile': true,
                'circle-mid-mobile-DE': DE,
                'circle-mid-mobile-FR': FR,
                'circle-mid-mobile-ES': ES,
                'circle-mid-mobile-RU': RU,
              }"
            >
              {{ getContent(1026) }}
            </div>
          </div>
          <div style="height: 2vw; background: #cccccc"></div>
          <div
            class="flexColumn"
            style="padding-top: 5vw; justify-content: center"
          >
            <div
              v-for="(bud, key) in buddyData.buddies"
              v-bind:key="key"
              :class="{
                'Selection-mobile': buddy != key + 1,
                'Selected-mobile': buddy == key + 1,
              }"
              @click="
                buddy = key + 1;
                scroll('#scrolltodiv');
              "
            >
              {{ bud.name }}: {{ bud.aboutMe }}
            </div>
            <div
              v-if="buddyData.buddies.length === 0"
              class="Selection-mobile"
              @click="link('Signin')"
            >
              {{ getContent(1239) }}
            </div>
            <div
              :class="{ TryOut: buddy != 0, TryOutGray: buddy == null }"
              @click="selectBuddy()"
            >
              {{ getContent(1031) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="scrolltodiv"></div>
    <div v-if="state == 10">
      <sign-up
        :answers="output"
        :support-type="supportType"
        :embedded="true"
        v-on:signUpComplete="signUpDone($event)"
        v-on:signin="state = 11"
      />
    </div>

    <div v-if="state == 11">
      <sign-in
        :answers="output"
        :support-type="supportType"
        :embedded="true"
        v-on:signUpComplete="signUpDone($event)"
        v-on:signup="state = 10"
      />
    </div>
  </div>
</template>

<script>
import SignUp from "@/views/Signup";
import SignIn from "@/views/Signin";
import config from "../config";
import {
  loadData,
  getAnswers,
  getNextQuestions,
  mapTypeToQuestion,
} from "@/utils";
import { axiosGet, axiosPost } from "@/utils/axiosWrapper";

const toLower = (text) => {
  return text.toString().toLowerCase();
};

const searchByName = (items, term) => {
  if (term) {
    return items.filter((item) => toLower(item.answer).includes(toLower(term)));
  }

  return items;
};

export default {
  name: "Questions",
  data() {
    return {
      config: config,
      myLanguage: this.$router.currentRoute.params.language.toUpperCase(),
      state: 0,
      query: false,

      buddy: null,
      buddyData: [],
      other: [
        214, 302, 344, 358, 374, 401, 428, 458, 562, 589, 651, 702, 731, 758,
        820, 829, 835, 842, 857, 879, 981, 987,
      ],
      value: null,

      search: null,
      searched: [],

      marked: [],
      answers: [],
      aorn: [[]],
      slider: 5,
      options: 0,
      Selection: 0,
      complete: true,
      showSnackbar: false,

      text: {},
      questionID: null,

      myStack: [],
      showDialog: false,
      output: [],
      supportType: 2,
      myWidth: screen.height / screen.width > 1,
      otherPopUp: false,
      SupportOthersPopUp: false,
      LanguageChangePopUp: false,

      NL: this.$router.currentRoute.params.language.toUpperCase() == "NL",
      DE: this.$router.currentRoute.params.language.toUpperCase() == "DE",
      FR: this.$router.currentRoute.params.language.toUpperCase() == "FR",
      AR: this.$router.currentRoute.params.language.toUpperCase() == "AR",
      ES: this.$router.currentRoute.params.language.toUpperCase() == "ES",
      RU: this.$router.currentRoute.params.language.toUpperCase() == "RU",
      SE: this.$router.currentRoute.params.language.toUpperCase() == "SE",
    };
  },
  components: {
    SignUp,
    SignIn,
  },
  methods: {
    getContent(key) {
      return this.text[key];
    },
    changeState(state) {
      this.state = state;
      //history.pushState({},state,"Questions?state="+state)
      this.$router.push("Questions?state=" + state);
    },
    link(l) {
      this.$router.push(l);
    },
    scroll(div) {
      this.$scrollTo(div, 500, { easing: "ease-in" });
    },
    searchOnTable() {
      this.searched = searchByName(this.answers, this.search);
    },
    onSelect(items) {
      this.marked = items;
      this.otherSelected();
    },
    async fillForm(id) {
      this.questionID = id;

      let data = await getAnswers(id);

      this.state = data.state;

      if (this.supportType === "2" && id === 90) {
        data.answerIDs = data.answerIDs.filter((id) => id != 961);
      }
      this.fillAnswers(data.answerIDs);
    },
    async fillFormAORN(ids) {
      this.state = 4;

      this.answers = ids.map((id) => ({
        answer: this.getContent(id),
        id: id,
      }));
    },
    fillAnswers(answerIDs) {
      switch (this.state) {
        case 4:
          break;
        case 5:
          break;
        case 6:
          this.answers = answerIDs.map((id) => ({
            answer: this.getContent(id),
            id: id,
          }));
          break;
        case 7:
          this.answers = answerIDs.map((id) => ({
            answer: this.getContent(id),
            id: id,
          }));
          this.search = "";
          this.searched = this.answers;
          break;
      }
    },
    compare(item) {
      if (this.other.includes(item)) this.otherPopUp = true;
    },
    submitOther(text, questionID) {
      if (this.state == 6) {
        this.answers.push({ answer: text, id: 9999 });
        this.options = 9999;
      }
      axiosPost("/other/", {
        id: questionID,
        text: text,
      });
      this.value = "";
    },
    otherSelected() {
      switch (this.state) {
        // Checkbox
        case 7: {
          if (this.marked != 0) {
            const ids = this.marked.map((x) => x.id);
            ids.forEach((element) => this.compare(element));
          }
          break;
        }
        // Options
        case 6: {
          this.compare(this.options);
          break;
        }
      }
    },
    async computeNextQuestions() {
      // Test ob Stack leer ist, wenn ja wird geprüft, ob Fragen übrig sind. Wenn Nein, dann fertig.
      if (this.myStack.length === 0) {
        this.$cookies.set("output", JSON.stringify(this.output));
        this.state = 10;
      }

      // special case for aorn questions
      if (
        (await mapTypeToQuestion(this.myStack[this.myStack.length - 1])).type ==
        1
      ) {
        let aorn = [];
        while (
          (await mapTypeToQuestion(this.myStack[this.myStack.length - 1]))
            .type == 1
        ) {
          aorn.push(this.myStack.pop());
        }
        this.fillFormAORN(aorn);
      } else this.fillForm(await this.myStack.pop());
    },
    async computeAnswers() {
      // let stack = this.myStack
      switch (this.state) {
        case 4: //AORN
          if (
            this.answers.map((e) => e.id).filter((id) => id == 127).length > 0
          ) {
            if (this.aorn[0] == "a" || this.aorn[0] == "o") {
              this.emergency();
            }
          }
          this.output = await this.output.concat(
            this.answers.map((e) => ({
              id: e.id,
              answer: this.aorn[this.answers.map((a) => a.id).indexOf(e.id)],
            }))
          );
          await this.$cookies.set("output", this.output);
          this.aorn = [];
          break;
        case 5: //Scale
          this.output = await this.output.concat([
            { id: this.questionID, answer: this.slider },
          ]);
          this.slider = 5;
          await this.$cookies.set("output", this.output);
          break;
        case 6: //Options
          // 128 Are you in danger at the moment or need immediate help?
          // 867 Are you in a life threatening situation?
          // 869 Do you want immediate help?
          // 898 Are you in a life threatening situation?
          // 899 Do you want immediate help?
          // 997 Are you in an emergency situation?
          if ([128, 867, 869, 898, 899, 997].includes(this.questionID))
            if ([1113, 1212, 1214, 1218, 1220, 1229].includes(this.options))
              // yes
              this.emergency();
          for (const q of await getNextQuestions(this.options)) {
            this.myStack = this.myStack.concat(q);
          }
          this.output = await this.output.concat([
            { id: this.questionID, answer: this.options },
          ]);
          await this.$cookies.set("output", this.output);
          this.options = 0;
          break;
        case 7: //Checkbox
          if (this.questionID === 939 || this.questionID === 90)
            if (
              this.marked.map((e) => e.id).includes(942) || // Shaking / Trembling
              this.marked.map((e) => e.id).includes(945) || // Chest tightness
              this.marked.map((e) => e.id).includes(961) // "I have difficulties with eating (anorexia/bulimia)"
            )
              // What are your physical symptoms when reminded,
              this.emergency();

          for (const e of this.marked.map((e) => e.id)) {
            const eEnriched = await getNextQuestions(e);
            this.myStack = this.myStack.concat(eEnriched);
          }
          this.output = await this.output.concat([
            {
              id: this.questionID,
              answer: await this.marked.map((e) => e.id),
            },
          ]);
          this.marked = [];
          break;
      }
      await this.$cookies.set("output", JSON.stringify(this.output));
      await this.$cookies.set("stack", this.myStack);
      this.scroll("#top");
      this.myStack.sort((a, b) => b - a);
      await this.computeNextQuestions();
    },
    emergency: function () {
      this.$router.push("Home#QuestionsEmergency");
    },
    signUpDone: function (user) {
      this.query = true;
      this.resetData();
      if (this.supportType == 0) {
        axiosGet("/matching/newSupporter/" + user._id)
          .then((res) => {
            res.data.buddies.forEach((e) =>
              axiosPost("/matching/", {
                userId: res.data.user._id,
                buddyId: e._id,
              })
            );
          })
          .catch((err) => console.warn(err))
          .finally(this.$router.push({ name: "Signin" }));
      } else {
        this.state = 8;
        axiosGet("/matching/" + user._id).then((res) => {
          this.buddyData = res.data;
          this.query = false;
        });
      }
    },
    selectBuddy: function () {
      axiosPost("/matching/", {
        userId: this.buddyData.user._id,
        buddyId: this.buddyData.buddies[this.buddy - 1]._id,
      })
        .then(() => {
          this.link("Signin");
        })
        .catch((err) => console.warn(err));
    },
    resetData: function () {
      return Promise.all([
        this.$cookies.remove("stack"),
        this.$cookies.remove("output"),
        this.$cookies.remove("supportType"),
      ]);
    },
    LanguageChange(item) {
      this.$cookies.set("Language", this.myLanguage);
      const restRoute = this.$router.currentRoute.path
        .split("/")
        .splice(2)
        .join("/");
      this.$router.push("/" + item + "/" + restRoute);
    },
  },
  async mounted() {
    this.text = {
      ...(await loadData("home", this.myLanguage)),
      ...(await loadData("Questions", this.myLanguage)),
    };

    if (this.$route.query.state) this.state = this.$route.query.state;
    if (this.state < 3 && !this.$cookies.get("Language"))
      this.LanguageChangePopUp = true;
    else this.myLanguage = this.$cookies.get("Language");
    if (this.$cookies.get("stack")) {
      this.myStack = await this.$cookies.get("stack");
      if (typeof this.myStack === "string")
        this.myStack = this.myStack.split(",").map((e) => parseInt(e));
    } else this.myStack = [999, 997, 988, 982, 90];
    if (this.$cookies.get("output")) {
      this.output = await JSON.parse(this.$cookies.get("output"));
    }
    if (this.$cookies.get("supportType")) {
      this.supportType = await this.$cookies.get("supportType");
    } else {
      if (this.state < 3) {
        this.supportType = this.state;
      }
    }
  },
};
</script>

<style scoped>
/* Questions Landing Styles */
.wrapper {
  width: 100%;
  padding: 0;
  margin: 0 auto;
  background-color: white;
}

.background {
  background-size: 100% 100%;
  --width: 100vw;
  width: calc(var(--width) - (100vw - 100%));
  height: calc(var(--width) * (972 / 1920));
  position: relative;
  float: left;
}

.mutualSupport {
  background-image: url("../assets/PeerSupport/questions/mutual.png");
}

.receiveSupport {
  background-image: url("../assets/PeerSupport/questions/receive.png");
}

.supportOthers {
  background-image: url("../assets/PeerSupport/questions/others.png");
}

.text {
  position: absolute;
  float: left;
  font-family: var(--h3);
  font-size: 1.6vw !important;
  line-height: 2vw !important;
  color: white;
  text-shadow: 1px 1px 2px #666;
  font-weight: 300 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.orange {
  left: 5%;
  top: 35%;
  width: 20%;
  height: 35%;
}

.blue {
  left: 40%;
  top: 45%;
  width: 20%;
  height: 30%;
}

.lightblue {
  left: 2%;
  top: 80%;
  width: 37%;
  height: 16%;
}

.next {
  left: 80%;
  top: 88%;
  width: 15%;
  height: 7.5%;
  background-color: #f1c289;
  border-radius: 20px;
  text-transform: capitalize;
  line-height: 3.5vw !important;
  font-size: 2vw !important;
  transition: all 0.2s ease-in-out;
  border: 2px solid #f1c289;
  box-sizing: border-box;
}

.next::after {
  content: "➫";
  position: relative;
  top: 3px;
  left: 3px;
}

.next:hover {
  cursor: pointer;
  border: 2px solid white;
  box-shadow: inset 0 0 5px #666;
}

/* END OF QUESTIONS LANDING STYLES */

.slidecontainer {
  width: 50%; /* Width of the outside container */
  margin-left: 3vw;
  margin-top: 40px;
}

.slidecontainer-mobile {
  width: 94vw; /* Width of the outside container */
  margin-left: 3vw;
  margin-right: 3vw;
  margin-top: 40px;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #4b565c;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4f83ac;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4f83ac;
  cursor: pointer;
}

.Information1 {
  background: #4b565c;
  color: #111010;
  align-items: center;
  padding-top: 2vh;
  flex-wrap: wrap;
  justify-content: space-around;
}

.Information2 {
  background: #4b565c;
  color: #111010;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 2vh;
  justify-content: space-around;
}

.Questionnaire1 {
  font-size: 1.7vw;
  line-height: 1.7vw;
  text-align: left;
  padding-top: 1vw;
  padding-left: 3vw;
  color: #009688;
  display: flex;
}

.Questionnaire1-mobile {
  font-size: 5vw;
  line-height: 5vw;
  text-align: left;
  padding-top: 1vw;
  padding-left: 3vw;
  color: #009688;
  display: flex;
}

.Questionnaire2 {
  font-size: 1vw;
  display: flex;
  line-height: 1vw;
}

.Questionnaire2-mobile {
  padding-top: 15px;
  display: flex;
}

.Questionnaire3 {
  font-size: 1vw;
  line-height: 1vw;
  display: flex;
  align-items: center;
}

.Scale1 {
  font-size: 1.7vw;
  line-height: 1.7vw;
  text-align: left;
  padding-top: 1vw;
  padding-left: 3vw;
  color: #009688;
}

.Scale1-mobile {
  font-size: 5vw;
  line-height: 5vw;
  text-align: left;
  padding-top: 1vw;
  padding-left: 3vw;
  color: #009688;
}

.Questions {
  font-size: 1.7vw;
  line-height: 1.7vw;
  text-align: left;
  padding-top: 1vw;
  padding-left: 3vw;
  color: #009688;
}

.Questions-mobile {
  font-size: 5vw;
  line-height: 5vw;
  text-align: left;
  padding-top: 4vw;
  padding-left: 3vw;
  color: #009688;
}

.Questions1 {
  padding-left: 5vw;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.Questions2 {
  padding-left: 5vw;
  padding-right: 15vw;
  line-height: 1.7vw;
}

.Questionnaire_Text {
  color: #4f83ac;
  font-size: 1.3vw;
  line-height: 1.3vw;
  font-weight: bold;
  width: 12vw;
  min-width: 48px;
}

.Questionnaire_Text-mobile {
  color: #4f83ac;
  font-size: 3vw;
  line-height: 3vw;
  font-weight: bold;
  width: 12vw;
}

.Choose {
  background: #80939c;
}

.Selection {
  background: #cccccc;
  margin: 1vw;
  padding: 1vw;
  border-radius: 40px;
  font-size: 2vw;
  line-height: 2vw;
  margin-left: 4vw;
  text-align: left;
  cursor: pointer;
}

.Selected {
  background: #868585;
  margin: 1vw;
  padding: 1vw;
  border-radius: 40px;
  font-size: 2vw;
  line-height: 2vw;
  margin-left: 4vw;
  text-align: left;
  font-weight: bold;
  cursor: pointer;
}

.Selection-mobile {
  background: #cccccc;
  margin: 1vw;
  padding: 3vw;
  border-radius: 40px;
  font-size: 4vw;
  line-height: 4vw;
  margin-left: 4vw;
  text-align: left;
  cursor: pointer;
}

.Selected-mobile {
  background: #868585;
  margin: 1vw;
  padding: 3vw;
  border-radius: 40px;
  font-size: 4vw;
  line-height: 4vw;
  margin-left: 4vw;
  text-align: left;
  font-weight: bold;
  cursor: pointer;
}

.TryOut {
  background: #009688;
  margin: 1vw;
  margin-right: 3vw;
  margin-top: 3vw;
  padding: 1vw;
  border-radius: 40px;
  font-size: 2vw;
  line-height: 2vw;
  margin-left: 4vw;
  text-align: left;
  align-self: flex-end;
  width: 10vw;
  font-weight: bold;
  cursor: pointer;
}

.TryOutGray {
  visibility: hidden;
}

.circle-big {
  width: 22vw;
  height: 22vw;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.circle-big-mobile {
  width: 22vw;
  height: 22vw;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 250px;
  min-height: 250px;
  margin: 10px;
}

.circle-big_which {
  width: 22vw;
  height: 22vw;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.circle-big_which-mobile {
  width: 22vw;
  height: 22vw;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 250px;
  min-height: 250px;
  margin: 10px;
}

.circle-big_which:hover {
  filter: brightness(140%);
}

.circle-mid {
  width: 20vw;
  height: 20vw;
  border-radius: 50%;
  margin-bottom: 2vw;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 2vw;
  line-height: 2vw;
  min-width: 20vw;
  min-height: 20vw;
  background: #009688;
}

.circle-mid-DE {
  font-size: 1.6vw;
  line-height: 1.6vw;
}

.circle-mid-FR {
  font-size: 1.7vw;
  line-height: 1.7vw;
}

.circle-mid-ES {
  font-size: 1.8vw;
  line-height: 1.8vw;
}

.circle-mid-RU {
  font-size: 1.9vw;
  line-height: 1.9vw;
}

.circle-mid-mobile {
  width: 20vw;
  height: 20vw;
  border-radius: 50%;
  margin-bottom: 2vw;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 4vw;
  line-height: 4vw;
  min-width: 150px;
  min-height: 150px;
  background: #009688;
}

.circle-small {
  width: 5vw;
  height: 5vw;
  margin: 8vw;
  border-radius: 50%;
  display: inline-block;
}

.circle-questionnaire {
  width: 8vw;
  height: 8vw;
  border-radius: 50%;
  display: flex;
  background: #7f8285;
  align-items: center;
  color: #ffffff;
  font-size: 1.8vw;
  line-height: 1.8vw;
  text-align: center;
  justify-content: center;
  min-width: 45px;
}

.circle-questionnaire-mobile {
  width: 8vw;
  height: 8vw;
  border-radius: 50%;
  display: flex;
  background: #7f8285;
  align-items: center;
  color: #ffffff;
  font-size: 4vw;
  line-height: 4vw;
  text-align: center;
  justify-content: center;
  min-width: 60px;
  min-height: 60px;
  margin-right: 4vw;
}

.img {
  height: 10vw;
  width: auto;
}

.img-mobile {
  height: 30vw;
  width: auto;
  align-self: flex-end;
  margin: 15px;
}

.img:hover {
  cursor: pointer;
}

.text {
  font-size: 1.5vw;
  font-weight: bold;
  line-height: 1.5vw;
  padding: 20px;
}

.text-NL {
  font-size: 1.5vw;
  line-height: 1.5vw;
}

.text-DE {
  font-size: 1.4vw;
  line-height: 1.4vw;
}

.text-FR {
  font-size: 1.5vw;
  line-height: 1.5vw;
}

.text-AR {
  font-size: 1.5vw;
  line-height: 1.5vw;
}

.text-ES {
  font-size: 1.4vw;
  line-height: 1.4vw;
}

.text-RU {
  font-size: 1.3vw;
  line-height: 1.3vw;
}

.text-SE {
  font-size: 1.5vw;
  line-height: 1.5vw;
}

.text-mobile {
  font-size: 4.5vw;
  font-weight: bold;
  line-height: 4.5vw;
  padding: 20px;
}

.text-mobile-RU {
  font-size: 4vw;
  line-height: 4vw;
}

.text-mobile-DE {
  font-size: 4vw;
  line-height: 4vw;
}

.text-small {
  font-size: 1.2vw;
  font-weight: bold;
  line-height: 1.2vw;
  padding: 20px;
}

.text-small-NL {
  font-size: 1.1vw;
  line-height: 1.1vw;
}

.text-small-DE {
  font-size: 1vw;
  line-height: 1vw;
}

.text-small-FR {
  font-size: 1.1vw;
  line-height: 1.1vw;
}

.text-small-RU {
  font-size: 1vw;
  line-height: 1vw;
}

.text-small-mobile {
  font-size: 3.5vw;
  font-weight: bold;
  line-height: 3.5vw;
  padding: 20px;
}

.text-small-mobile-DE {
  font-size: 3.4vw;
  line-height: 3.4vw;
}

.text-small-mobile-FR {
  font-size: 3.1vw;
  line-height: 3.1vw;
}

.text-small-mobile-RU {
  font-size: 2.9vw;
  line-height: 2.9vw;
}

.Checkbox {
  width: 12vw;
}

.md-table-cell {
  font-size: 1.7vw;
  line-height: 3vw;
}

.md-radio-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4vw 0vw 4vw 2vw;
}

.snackbar {
  top: 0;
}

.img-language-mobile {
  padding-top: 16px;
  margin-right: 5px;
  width: 32px;
}

.img-language-desktop {
  margin-right: 5px;
  height: 32px;
  width: 32px;
}
</style>

<style>
.md-table-head-label {
  font-size: 1.4vw;
}

@media screen and (max-width: 640px) {
  .md-table-head-label {
    font-size: 5vw;
  }
}
</style>
